import React, { useState, useEffect } from 'react';
import { useTournament } from 'context/TournamentContext';

import {
  BannerImg,
  Wrap,
} from './styled';
import { LANDING } from 'constants/routes';

const Banner = () => {
  const { tournamentDetails } = useTournament();
  const tournamentId = tournamentDetails?.data?.id;
  const [bannerClass, setBannerClass] = useState(null);
  const [bannerLink, setBannerLink] = useState(LANDING);

  useEffect(() => {
    if (tournamentId === 82 ) {
      setBannerClass('banner_casper')
      setBannerLink('https://casper.network/en-us/')
    }
    if (tournamentId === 85 || tournamentId === 168 ) {
      setBannerClass('banner_bitomat')
      setBannerLink('https://www.bitomat.com/')
    }
    if (tournamentId === 87 ) {
      setBannerClass('banner_bitget')
      setBannerLink('https://www.bitget.com/')
    }
    if (tournamentId === 120 ) {
      setBannerClass('banner_KryptoSilesia')
      setBannerLink('')
    }
    if (tournamentId === 122 ) {
      setBannerClass('banner_BL')
      setBannerLink('https://blackfort.exchange/')
    }
    if (tournamentId === 125 ) {
      setBannerClass('banner_coinex')
      setBannerLink('https://www.coinex.com/s/4JHE')
    }
    if (tournamentId === 124 ) {
      setBannerClass('banner_Time3xBanner')
      setBannerLink(null)
    }
    if (tournamentId === 165 ) {
      setBannerClass('banner_Time2xBanner')
      setBannerLink(null)
    }
    if (tournamentId === 229 ) {
      setBannerClass('banner_ETHdam')
      setBannerLink(null)
    }
    if (tournamentId === 230 ) {
      setBannerClass('banner_BF25')
      setBannerLink(null)
    }
  }, [tournamentDetails?.data?.id, tournamentId]);

  return (
    <Wrap>
      <p className="TitleBanner">Sponsored by:</p>
      <a href={bannerLink} target="_blank" rel="noreferrer">
        <BannerImg className={bannerClass}></BannerImg>
      </a>
    </Wrap>
  );
};

export default Banner;
