import styled from 'styles/styled';
import imgBanner from '../../../assets/Banner/banner.png';
import imgBannerCasper from '../../../assets/Banner/casper.png';
import imgBannerBitomat from '../../../assets/Banner/bitomat.png';
import imgBannerBitget from '../../../assets/Banner/Bitget.png';
import imgBannerKryptoSilesia from '../../../assets/Banner/ksbr.png';
import imgBannerBL from '../../../assets/Banner/bw_banner.png';
import imgBannerCoinEx from '../../../assets/Banner/coinex.png';
import imgBannerAri10 from '../../../assets/Banner/BanerAri10.png';
import imgBannerCashify from '../../../assets/Banner/BanerCashify.png';
import imgBannerTrustee from '../../../assets/Banner/BanerTrustee.png';
import imgBannerBitmex from '../../../assets/Banner/bitmex.png';
import imgBannerCoinListings from '../../../assets/Banner/coinlistings.jpg';
import imgBannerETHdam from '../../../assets/Banner/bannerETHdam.jpeg';
import imgBannerBF25 from '../../../assets/Banner/bannerBF2025.jpeg';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
  align-items: center;
  width: 100%;
  .TitleBanner {
    color: #fefefe;
    margin-right: 10px;
    @media (max-width: 1200px) {
      display: none;
    }
  }
`;

export const BannerImg = styled.div`
  background-color: #2e2e2e;
  max-width: 90%;
  width: 360px;
  border-radius: 10px;
  height: 42px;
  display: block;
  position: relative;
  background-image: url(${imgBanner});
  background-size: cover;
  background-position: center;
  @media (max-width: 1300px) {
    height: 34px;
    width: 280px;
  }
  @media (max-width: 1020px) {
    height: 30px;
    border-radius: 8px;
    width: 220px;
  }
  @media (max-width: 420px) {
    width: 200px;
  }
  @media (max-width: 360px) {
    width: 160px;
    height: 26px;
  }
  &:hover {
    opacity: 1;
  }
  &.banner_ETHdam {
    background-image: url(${imgBannerETHdam});
  }
  &.banner_BF25 {
    background-image: url(${imgBannerBF25});
  }
  &.banner_casper {
    background-image: url(${imgBannerCasper});
  }
  &.banner_bitomat {
    background-image: url(${imgBannerBitomat});
  }
  &.banner_bitget {
    background-image: url(${imgBannerBitget});
  }
  &.banner_KryptoSilesia {
    background-image: url(${imgBannerKryptoSilesia});
  }
  &.banner_BL {
    background-image: url(${imgBannerBL});
  }
  &.banner_coinex {
    background-image: url(${imgBannerCoinEx});
  }
  &.banner_Time3xBanner {
    background-image: url(${imgBannerAri10});
    animation: animationBanner_X 15s;
    animation-delay: 0ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
  }
  &:after {
    content: "Sponsored by:";
    position: absolute;
    top: -9px;
    left: 12px;
    font-weight: 400;
    line-height: 8px;
    font-size: 6px;
    color: #EFEFEF;
    @media (min-width: 1200px) {
      display: none;
    }
  }
  &.banner_Time2xBanner {
    background-image: url(${imgBannerCoinListings});
    animation: animationBanner_2_X 10s;
    animation-delay: 0ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
  }

  @keyframes animationBanner_X {
    0% { background-image: url(${imgBannerCashify}); }
    32% { background-image: url(${imgBannerCashify}); }
    33% { background-image: url(${imgBannerTrustee}); }
    65% { background-image: url(${imgBannerTrustee}); }
    66% { background-image: url(${imgBannerAri10}); }
    99% { background-image: url(${imgBannerAri10}); }
  }

  @keyframes animationBanner_2_X {
    0% { background-image: url(${imgBannerCoinListings}); }
    49% { background-image: url(${imgBannerCoinListings}); }
    50% { background-image: url(${imgBannerBitmex}); }
    99% { background-image: url(${imgBannerBitmex}); }
  }
`;
